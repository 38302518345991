import { ErrorHandler } from '@angular/core';
import { Injectable, Inject, Optional } from '@angular/core';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  handleError(error: any): void {
    debugger;
    console.log(error);
   const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}