import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/common/storage.service';
import { environment } from 'src/environments/environment';

import { AuthGuard } from 'src/app/modules/core/guards/auth.guard';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  apiUrl: string;
  header: any;
  token:any;
  refreshtoken=localStorage.getItem('refreshtoken');
  
  constructor(private http: HttpClient,  private readonly storageService: StorageService, private readonly router: Router,) {
    // this.apiUrl = `${environment.baseUrl}/v${environment.currentVersion}/`;
    this.apiUrl = `${environment.baseUrl}`;
    this.header = new HttpHeaders();
   
  }
  checktoken(){
     //get new token if the current token expired 
   if(this.router.url!="/login"){
    var tokenExpiry = new Date(this.storageService.user.exp * 1000);
    var today = new Date();
    if (tokenExpiry < today) {
     this.getnewtoken().subscribe(
      (res: any) => {
        debugger;
        if (res.Status==true) {
          this.storageService.JWTToken = res.Token;
          localStorage.setItem('refreshtoken', res.RefreshToken )
        return true
        } 
        else{
          this.router.navigate(["login"]);
          this.storageService.clearAll();
          return false
        }
      })
  
    }
    else{
      return true
    }
    
 }
 //end
  }
  //get new token using expired token and refresh token
  getnewtoken(){
    let headers = new HttpHeaders();
    headers = headers.set('Loader', 'false');
    return this.http.post(this.apiUrl+"v1.0/Account/GetNewTokensAsync?token="+this.storageService.JWTToken+"&refreshToken="+this.refreshtoken,{ headers: headers }).pipe(map(res => {
      return res
    }));
  }
  //end
  //post fORM DATA
  postFormData(url: string, postData: any, isShowGlobalLoader:boolean) {
    debugger
    
    let headers = new HttpHeaders().set(
      "Access-Control-Allow-Origin",
      "*" 
    );
     if(isShowGlobalLoader)
    
        headers = headers.set('Loader', 'true');
      
    return this.http.post(this.apiUrl + url, postData, { headers: headers }).pipe(map(res => {
      return res;
    }));
    
  }
  //  HTTP `POST`
  
  post(url: string, postData: any, isShowGlobalLoader:boolean) {
    debugger
    let headers = new HttpHeaders();
    console.log(headers)
    if(isShowGlobalLoader)
      headers = headers.set('Loader', 'true');
    return this.http.post(this.apiUrl + url, postData, { headers: headers }).pipe(map(res => {
      return res;
    }));
    
  }
  postwithtoken(url: string, postData: any, isShowGlobalLoader:boolean) {
    this.token=this.storageService.JWTToken;
    let headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + this.token
    );
    if(isShowGlobalLoader)
      headers = headers.set('Loader', 'true');
    return this.http.post(this.apiUrl + url, postData, { headers: headers }).pipe(map(res => {
      return res;
    }));
    
  }
  postWithTokenOnly(url: string, postData: any,isShowGlobalLoader:boolean) {
    this.token=this.storageService.JWTToken;
    let headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + this.token
    );
    if(isShowGlobalLoader)
      headers = headers.set('Loader', 'true');
    return this.http.post(this.apiUrl + url,postData, { headers: headers }).pipe(map(res => {
      return res;
    }));
    
  }
  postAsQueryString(url: string,isShowGlobalLoader:boolean) {
    let headers = new HttpHeaders();
      if(isShowGlobalLoader)
        headers = headers.set('Loader', 'true');
      return this.http.post(this.apiUrl + url,{ headers: headers }).pipe(map(res => {
        return res;
      }));
  }
  //  HTTP `GET`
  get(url: string, isShowGlobalLoader:boolean) {
    let headers = new HttpHeaders();
    
      if(isShowGlobalLoader)
        headers = headers.set('Loader', 'true');
      return this.http.get(this.apiUrl + url, { headers: headers }).pipe(map(res => {
        return res;
      }));
   
  }
 //  HTTP `GET`External
//  getExternal(url: string, isShowGlobalLoader:boolean) {
//   let headers = new HttpHeaders();
  
//     if(isShowGlobalLoader)
//       headers = headers.set('Loader', 'true');
//     return this.http.get( url).pipe(map(res => {
//       return res;
//     }));
 
// }
  //  HTTP `PUT`
  put(url: string, putData: any, isShowGlobalLoader:boolean) {
    let headers = new HttpHeaders();
    if(isShowGlobalLoader)
      headers = headers.set('Loader', 'true');
    return this.http.put(this.apiUrl + url, putData).pipe(map(res => {
      return res;
    }));
  }

  //  HTTP `DELETE`
  delete(url: string, isShowGlobalLoader:boolean) {
    let headers = new HttpHeaders();
    if(isShowGlobalLoader)
      headers = headers.set('Loader', 'true');
    return this.http.delete(this.apiUrl + url).pipe(map(res => {
      return res;
    }));
    
  }

   //  HTTP `POST` for file download
   export(url: string, postData: any, isShowGlobalLoader:boolean) {
    let headers = new HttpHeaders();
      if(isShowGlobalLoader)
        headers = headers.set('Loader', 'true');
        return this.http.post<Blob>(this.apiUrl + url, postData, { headers: headers, responseType: 'blob' as 'json' }).pipe(map(res => {
          return res;
        }));
   
  }
}
