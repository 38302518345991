// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   // Test
  //  baseUrl1: `https://tapi.klasfox.com/api/`,
  //  baseUrl: `https://localhost:44385/api/`,
  //  ImageUrl:`https://tapi.klasfox.com/`,
  //  resetlink:`https://tadmin.klasfox.com/#/reset-password/`,




  // old API
  // baseUrl: `http://202.0.103.128:8034/api/`,
  // ImageUrl:`http://202.0.103.128:8034/`,
  // resetlink:`http://202.0.103.128:8039/#/reset-password/`,

//live
  baseUrl: `https://api.klasfox.com/api/`,
  ImageUrl:`https://api.klasfox.com/`,
  resetlink:`https://admin.klasfox.com/#/reset-password/`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
