import { BrowserModule, Title } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule, LocationStrategy } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from "ngx-toastr";
import { LoadingService } from './services/common/loading.service';
import { LoadingInterceptorService } from './services/common/loading-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';
import { AuthInterceptor } from './modules/core/interceptor/auth.interceptor';
import { CommonMethodService } from './services/common/common-method.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { GlobalErrorHandler } from './GlobalErrorHandler ';
import { HashLocationStrategy} from '@angular/common';

@NgModule({
  
  imports: [
    CommonModule ,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    Ng2SearchPipeModule,
    MatDatepickerModule,
    Ng2TelInputModule,
    NgxIntlTelInputModule,
    FlatpickrModule.forRoot(),
    SharedModule.forRoot(),
    ToastrModule.forRoot(),    
    BrowserAnimationsModule, FontAwesomeModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }), NgbModule,
   
  ],
  declarations: [
    AppComponent,
    
  ],
  providers: [Title, CommonMethodService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    LoadingInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (service: LoadingService) => new LoadingInterceptorService(service),
      multi: true,
      deps: [LoadingService]
    },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
