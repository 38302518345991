import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)}, 
  { path: '', loadChildren: () => import('./modules/core/core.module').then(m => m.CoreModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    // If you want to preload all lazy routes when the app loads, uncomment the following line
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: "reload",
    // This value is required for server-side rendering to work.
    initialNavigation: "enabled",
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
