import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
const commonTitle = 'IELTS-PLUS';

@Injectable()
export class CommonMethodService {

  loginSession: Subject<boolean> = new BehaviorSubject<boolean>(null);
  title = new Subject<string>();

  constructor(private _titleService: Title) {

   }

   clearAllSubjects() {
    this.title.next();
    this.loginSession.next();
  }
   
   setTitle(title: string) {
    if (title !== undefined) {
      this.title.next();
      this.title.next(title);
      this._titleService.setTitle(`${title} - ${commonTitle}`);
    }
  }

  getTitle(): Observable<any> {
    return this.title.asObservable();
  }

  clearTitle() {
    this.title.next('');
  }

  setLoginSession(isLogin: boolean) {
    if (isLogin !== undefined) {
      this.loginSession.next(isLogin);
    }
  }
}
